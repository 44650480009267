import default_template from './default_template.js';
import template1 from './template1.js';
import template2 from './template2.js';
import bulut_template from './bulut_template.js';
export function getInvoiceTemplate(template){
    switch(template){
        case 'default_template':
            return default_template;
        case 'template1':
            return template1;
        case 'template2':
            return template2;
        case 'bulut_template':
            return bulut_template
        default:
            return default_template;
    }
}